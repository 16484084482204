<template>
  <div class="py-8">
    <div class="px-6">
      <v-app-bar color="transparent" flat>
        <app-bar-nav-icon />
        <v-toolbar-title class="breadcrumbs d-flex align-center">
          <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
          <router-link :to="{ name: 'activity' }" class="text-decoration--none">
            Back to activity logs
          </router-link>
        </v-toolbar-title>
      </v-app-bar>
    </div>

    <div class="mt-5 px-12 mb-10">
      <div class="display-2 font-weight-bold my-6 text-capitalize">
        Activity Details
      </div>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="6">
          <v-row>
            <p class="display-1">
              <strong>Date &amp; Time</strong>
            </p>
          </v-row>
          <v-row>
            <p class="title">
              {{ activity.addedDate }}
            </p>
          </v-row>
          <v-row>
            <p class="headline">
              <strong>Type:</strong>
              {{ activity.type }}
            </p>
          </v-row>
          <v-row>
            <p class="headline">
              <strong>Description:</strong>
              {{ activity.description }}
            </p>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <div class="display-1">Changes</div>
        <v-col cols="12">
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-left">Key</th>
                <th width="40%" class="text-left">Old Value</th>
                <th width="40%">Current Value</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(attribute, key) in activity.properties.attributes"
                :key="attribute.id"
              >
                <td class="py-2 px-0">
                  {{ key }}
                </td>
                <td class="py-2 px-0" v-if="activity.properties.old">
                  {{ activity.properties.old[key] }}
                </td>
                <td v-else>---</td>
                <td class="py-2 px-0">
                  {{ attribute }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mdiChevronLeft, mdiCheck, mdiAlertCircleOutline } from '@mdi/js'
import { mapState, mapActions, mapMutations } from 'vuex'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'

export default {
  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      icons: {
        arrowLeft: mdiChevronLeft,
        check: mdiCheck,
        alert: mdiAlertCircleOutline,
      },
      loading: false,
    }
  },

  computed: {
    ...mapState({ activity: (state) => state.activity.activityDetails }),
  },

  methods: {
    ...mapActions({ getActivityDetails: 'activity/getActivityDetails' }),
    ...mapMutations({ clearActivityDetails: 'activity/clearActivityDetails' }),

    async getActivity() {
      this.loading = true
      await this.getActivityDetails(this.$route.params.id)
      this.loading = false
    },
  },

  created() {
    this.getActivity()
  },

  destroyed() {
    this.clearActivityDetails()
  },
}
</script>
